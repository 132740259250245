<template>
  <div class="tablePrint">
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.write_conclusions") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple text-center">
            <div class>
              {{ $t("message.patient_0") }}:
              <b style="text-transform: uppercase">{{
                order_model.patient
                  ? order_model.patient.first_name +
                    " " +
                    order_model.patient.surname
                  : " - "
              }}</b>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button
                v-if="activeName == 'second'"
                :loading="waitingPrint"
                type="success"
                @click="print('conclusion/print?id=' + old_form.id)"
                >{{ $t("message.printing") }}</el-button
              > -->
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="modal-body editor-content">
          <!-- <el-steps :active="active" finish-status="success" simple>
            <el-step
              :disabled="true"
              @click.native="tabTrigger(1, 'first')"
              :title="$t('message.write_conclusions')"
            ></el-step>
            <el-step
              @click.native="tabTrigger(2, 'second')"
              :title="$t('message.printing')"
            ></el-step>
          </el-steps> -->

          <el-tabs v-model="activeName">
            <el-tab-pane name="first">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-select
                    class="autoInput"
                    v-model="form.material_id"
                    filterable
                    clearable
                    :placeholder="$t('message.material')"
                  >
                    <el-option
                      v-for="item in materials"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-input
                    clearable
                    size="medium"
                    v-model="form.used_cassettes"
                    :placeholder="$t('message.used_cassettes')"
                  ></el-input>
                </el-col>
                <el-col :span="6">
                  <el-input
                    clearable
                    size="medium"
                    v-model="form.used_glasses"
                    :placeholder="$t('message.used_glasses')"
                  ></el-input>
                </el-col>
                <el-col :span="5">
                  <div class="d-flex print_title_list">
                    <!-- <div class="ttitle_Text">
                        {{
                            selectedTemplate
                                ? selectedTemplate.name
                                : ""
                        }}
                    </div> -->
                    <div class="d-flex">
                      <el-button
                        size="small"
                        icon="el-icon-delete"
                        style="height: 38px"
                        type="danger"
                        @click="clear()"
                        >{{ $t("message.clear") }}</el-button
                      >
                      <el-button
                        style="height: 38px"
                        type="primary"
                        :disabled="
                          form.content == '' || loadingButton ? true : false
                        "
                        @click="submit"
                      >
                        {{ $t("message.save_and_go") }}
                        <i class="el-icon-arrow-right el-icon-right"></i>
                      </el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20" style="margin-top: 20px">
                <el-col :span="12">
                  <div style="width: 500px;text-align: center">
                    <span>Дата и время: </span>
                    <span
                      ><b>{{ form.date_time }}</b></span
                    >
                  </div>
                </el-col>
                <!-- <el-col :span="10">
                  <div style="text-align: center">
                    <span>{{ $t("message.diagnos") }}: </span>
                    <span
                      ><b>{{ form.diagnos }}</b></span
                    >
                  </div>
                </el-col> -->
              </el-row>

              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="Editor_widht">
                    <div class="py-3">
                      <el-input
                        :placeholder="$t('message.diagnos')"
                        type="textarea"
                        :rows="2"
                        v-model="form.diagnos"
                      />
                    </div>
                    <el-card shadow="always">
                      <Tinymce
                        :id="tinymceId"
                        ref="editor"
                        class="naw_Editor"
                        v-model="form.content"
                        :height="800"
                      />
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
            <!-- ****************** end el-tab-pane  ******************-->
            <el-tab-pane name="second">
              <el-card
                class="mt-4"
                shadow="always"
                style="width: 1000px; margin: 0 auto"
              >
                <div class="content-table" v-html="form.content"></div>
              </el-card>
            </el-tab-pane>
            <!-- ****************** end el-tab-pane  ******************-->
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import print from "@/utils/mixins/print";
import drawerChild from "@/utils/mixins/drawer-child";
import Tinymce from "@/components/Tinymce";

export default {
  mixins: [print, drawerChild],
  props: {
    serviceId: { default: null },
  },
  components: {
    Tinymce,
  },
  data() {
    return {
      tinymceId: "create_conslusion_tiny",
      fileList: [],
      favorites: [],
      loadingButton: false,
      active: 0,
      activeName: "first",
      template: "",
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      templates: [],
      loadingTemplates: false,
      nextButton: true,
      changeTemplateButton: false,
      selectedTemplate: {},
      old_form: "",
      form: {
        name: "",
        material_id: "",
        header_template: "",
        content: "",
        used_cassettes: "",
        diagnos: "",
        brief_conclusion: "",
        used_glasses: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    form: {
      handler: function () {},
    },
    serviceId: {
      handler: function () {
        if (this.serviceId) {
          this.form.service_id = this.serviceId;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      tempList: "formTemplate/all",
      rules: "laboratoryConclusions/rules",
      model: "laboratoryConclusions/model",
      order_model: "orders/relation_model",
      materials: "materials/list",
      columns: "laboratoryConclusions/columns",
      user_id: "auth/id",
    }),
  },
  async mounted() {
    if (this.serviceId) {
      this.form.service_id = this.serviceId;
    }

    if (this.tempList && this.tempList.length === 0) {
      this.loadTemps();
    }
    if (this.materials && this.materials.length === 0) this.loadMaterial();
    // console.log(this.afterOpen);
    await this.categoryDocumentation();
    await this.MyFavorites();
    this.form.date_time = this.order_model.order_services[0].lifetime;
    this.form.diagnos = this.order_model.diagnos;
  },
  methods: {
    ...mapActions({
      loadTemps: "formTemplate/list",
      doctorTemplate: "formTemplate/doctorTemplate",
      loadMaterial: "materials/index",
      doctorTemplates: "formTemplate/doctorTemplates",
      categoryDocumentationList: "categoryDocumentation/index",
      save: "laboratoryConclusions/store",
      update: "laboratoryConclusions/update",
    }),
    categoryDocumentation() {
      this.categoryDocumentationList({ relation: true })
        .then((res) => {
          this.data = res.data.category_forms;
        })
        .catch((err) => {});
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    submit(close = true) {
      this.form.order_id = this.order_model.id;
      this.form.patient_id = this.order_model.patient_id;

      var formData = new FormData();
      for (var key in this.form) {
        if (this.form[key] != null) {
          formData.append(key, this.form[key]);
        } else {
          formData.append(key, "");
        }
      }
      // this.$refs["form"].validate((valid) => {
      // if (valid) {
      this.loadingButton = true;
      this.save(formData)
        .then((res) => {
          this.old_form = res.data.patient_conculation;
          this.loadingButton = false;
          this.$alert(res);
          this.activeName = "second";
          this.active++;
          if (
            this.$parent &&
            this.$parent.$parent &&
            _.isFunction(this.$parent.$parent.listChanged)
          ) {
            this.$parent.$parent.listChanged();
          }
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
      // }
      // });
    },
    updateConculation() {
      this.loadingButton = true;
      this.update({ id: this.old_form.id, data: this.form })
        .then((res) => {
          this.old_form = res.data.patient_conculation;
          this.loadingButton = false;
          this.$alert(res);
          this.activeName = "three";
          this.active++;
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
    },
    tabTrigger(event, tab_value) {
      if (this.active >= event) {
        this.active = event - 1;
        this.activeName = tab_value;
      }
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.form = {
        name: "",
        service_id: null,
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      };
      this.old_form = "";
      this.active = 0;
      this.activeName = "first";
      this.templates = [];
      this.selectedTemplate = {};
      this.nextButton = true;
      this.$emit("c-close", { drawer: "drawerCreateCoculation" });
    },

    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
    stringToHTML(str) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(str, "text/html");
      return doc.body;
    },
    selected(event) {
      if (event.category_id) {
        let tm = _.find(this.tempList, function (item) {
          return item.id == event.id;
        });
        if (tm) {
          this.selectedTemplate = tm;
          this.form.form_template_id = tm.id;
          this.form.content = this.form.content + "<br>" + tm.content;
        }
      } else {
        this.selectedTemplate = {};
        this.form.form_template_id = null;
        this.form.content = "";
      }
      if (this.form.content != "") this.nextButton = false;
      else this.nextButton = true;
      this.setToTinyContent();
    },
    addFavorites(id) {
      this.doctorTemplate({ template_id: id })
        .then((res) => {
          this.MyFavorites();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    MyFavorites() {
      this.doctorTemplates()
        .then((res) => {
          this.favorites = res.data;
          this.categoryDocumentation();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.form.file = event.target.files[0];
      }
    },
    afterOpened() {
      this.form.service_id = this.serviceId;
      this.form.material_id = this.order_model.material_id;
    },
    afterClosed() {
      this.form.content = "";
      this.setToTinyContent();
    },
    clear() {
      this.form.content = "";
      this.setToTinyContent();
    },
    chanseService(id) {
      this.form.service_id = id;
    },
    setToTinyContent() {
      window.tinymce.get(this.tinymceId).setContent(this.form.content || "");
    },
  },
};
</script>
<style lang="scss">
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  height: 600px !important;
}
.documentation_content .el-card.is-always-shadow {
  min-height: 700px;
}
.editor-content .content-table {
  max-height: 636px;
  overflow: scroll;
}
.editor-content .el-step__title.is-success {
  cursor: pointer;
}
.editor-content .el-step__title.is-process,
.el-step__title.is-wait {
  cursor: no-drop;
}
// .modal-body .el-tabs__header.is-top{
//     // margin:0
// }
.custom-tree-node {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.tree-meta-price {
  display: block;
  padding-right: 50px;
  font-weight: bold;
}
.favorites > .el-tag.el-tag--success.el-tag--light > .el-icon-close:before,
.favorites > .el-icon-close:before {
  content: none !important;
}
</style>
<style lang="scss">
.tablePrint {
  .el-tabs__nav-wrap.is-top {
    display: none;
  }
  .boxshii {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    // margin-top: 46px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 7px;
    border: 1px solid #ebeef5;
  }
}
.print_title_list {
  justify-content: space-between;
}
.Editor_widht .el-card__body,
.elCARD .el-card__body {
  padding: 0px;
}
.xh_scrol {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.xh_scrol::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.xh_scrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
.xh_scrol::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}
</style>